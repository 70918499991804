<template>
  <v-container fluid>
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-form ref="form" v-model="valid" class="ma-4" v-if="about">
      <!-- 名前 -->
      <v-row>
        <v-col class="pb-0">
          <v-text-field
            v-model="about.name"
            placeholder="名前"
            persistent-placeholder
            outlined
            :rules="rules.required"
          >
            <template v-slot:label>
              名前<span class="ml-2 red--text">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- 説明 -->
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-textarea
            v-model="about.description"
            label="説明"
            placeholder="説明"
            persistent-placeholder
            rows="5"
            outlined
          >
          </v-textarea>
        </v-col>
      </v-row>

      <!-- URL -->
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-text-field
            v-model="about.url"
            label="URL"
            placeholder="URL"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>

      <!-- 営業時間 -->
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-textarea
            v-model="about.workTime"
            label="営業時間"
            placeholder="例) 平日 10:00-19:00 土日祝 10:00-21:00 不定休"
            persistent-placeholder
            rows="5"
            outlined
          >
          </v-textarea>
        </v-col>
      </v-row>

      <!-- 郵便番号 -->
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-text-field
            v-model="about.postalCode"
            label="郵便番号"
            placeholder="例) 1234567 (数字のみ)"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>

      <!-- 住所-->
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-text-field
            v-model="about.address"
            label="住所"
            placeholder="例) 福岡県福岡市博多区123 ◯◯ビル"
            persistent-placeholder
            outlined
            @blur="applyGoogleMap"
            @keyup.enter="applyGoogleMap"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <!-- 地図 -->
      <v-row v-show="googleMapSrc">
        <v-col class="pt-0 pb-0 mb-8 googlemap">
          <iframe
            :src="googleMapSrc"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          />
        </v-col>
      </v-row>

      <!-- 連絡先 (電話) -->
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-text-field
            v-model="about.phone"
            placeholder="例) 0120-123-456"
            persistent-placeholder
            outlined
            :rules="rules.required"
          >
            <template v-slot:label>
              連絡先 (電話番号)<span class="ml-2 red--text">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- 支払方法 (スマホ決済) -->
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-textarea
            v-model="about.wayOfPay"
            label="支払方法 (スマホ決済)"
            placeholder="例) LINE Pay / PayPay"
            persistent-placeholder
            rows="5"
            outlined
          >
          </v-textarea>
        </v-col>
      </v-row>

      <!-- 画像 (アイコン) -->
      <p class="mt-16 text-h3">アイコン画像</p>
      <StorageView
        v-if="iconDirPath"
        class="mb-16"
        :storage-dir-path="iconDirPath"
        :init-select-img-url="initSelectIconUrl"
        :handle-img-selected="onIconSelected"
        :handle-img-deleted="onIconDeleted"
      />

      <!-- 画像 (店舗) -->
      <p class="mt-16 text-h3">店舗画像</p>
      <StorageView
        v-if="imgDirPath"
        class="mb-16"
        :storage-dir-path="imgDirPath"
        :init-select-img-url="initSelectImgUrl"
        :handle-img-selected="onImgSelected"
        :handle-img-deleted="onImgDeleted"
      />

      <!-- QR コード -->
      <p v-if="qrUri" class="mt-8 text-h3">配布用</p>
      <v-row class="ma-0" align="center">
        <v-col cols="auto" class="pa-0 ma-0">
          <v-img :src="qrUri" width="120" @click="saveQrCode" />
        </v-col>
        <v-col class="pa-0 ma-0 ml-8">
          <v-row class="" align="center">
            <v-col class="pa-0 ma-0">
              <p>
                この URL を読み込むと「店舗メニュー」が表示されます。
                リッチメニューなどに設定してご利用ください。
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-4" align="center">
            <v-col class="pa-0 ma-0" sm="8">
              <v-text-field
                v-model="miniAppUrl"
                label="クリックするとコピーできます"
                outlined
                readonly
                v-clipboard:copy="miniAppUrl"
                v-clipboard:success="onCopy"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- ボタン -->
      <v-row class="mt-16">
        <v-col class="pt-0 pb-0">
          <v-btn
            :disabled="!valid"
            class="primary mt-4"
            large
            block
            @click="onClickRegister"
            >{{ btnName }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <!-- snackbar -->
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      outlined
      right
    >
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import QRCode from "qrcode";
import { saveAs } from "file-saver";

export default {
  name: "About",
  components: {
    StorageView: () => import("./components/upload/StorageView"),
  },
  props: {},
  data: () => ({
    // form
    valid: true,
    rules: {
      required: [(v) => !!v || "必須入力です"],
    },

    googleMapSrc: "",
    btnName: "",

    // StorageView
    imgDirPath: null,
    iconDirPath: null,
    initSelectImgUrl: null,
    initSelectIconUrl: null,
    currentSelectedImg: null,
    currentSelectedIcon: null,

    // QR
    qrUri: null,
    miniAppUrl: null,

    // snackbar
    snack: false,
    snackColor: "",
    snackText: "",
  }),
  computed: {
    ...mapGetters(["progress", "about"]),
  },
  watch: {
    about: function () {
      this.applyData();
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      "getMiniAppUrl",
      "fetchAbout",
      "updateAbout",
      "getStoragePath",
    ]),
    async init() {
      this.fetchAbout();
      this.btnName = "店舗情報を更新する";
    },
    async applyData() {
      const about = this.about;

      // 画像
      this.initStorageView(about.imgUrl, about.iconUrl);

      // Google Map
      this.applyGoogleMap();

      // QRコード
      this.showQrCode();
    },
    async showQrCode() {
      // QRコード作成 -> 表示
      const miniAppUrl = await this.getMiniAppUrl();
      this.miniAppUrl = miniAppUrl;
      const options = {
        width: 600,
      };
      QRCode.toDataURL(miniAppUrl, options, (_, dataUri) => {
        // console.log("qr", dataUri);
        this.qrUri = dataUri;
      });
    },
    async saveQrCode() {
      const dataUri = this.qrUri;
      if (!dataUri) {
        console.log("[saveQrCode] do nothing");
        return;
      }
      const blob = await (await fetch(dataUri)).blob();
      saveAs(blob);
    },
    async onClickRegister() {
      try {
        // 画像
        const selectedImgUrl = this.currentSelectedImg?.src ?? null;
        const selectedIconUrl = this.currentSelectedIcon?.src ?? null;

        // 登録/更新データ作成
        const dbData = this.about;
        dbData.imgUrl = selectedImgUrl;
        dbData.iconUrl = selectedIconUrl;
        console.log("register", dbData);

        // 更新処理
        await this.updateAbout({ about: dbData });

        this.showSnackbar("更新しました");
      } catch (e) {
        console.error(e);
        this.showSnackbar("更新に失敗しました");
      }
    },
    onCopy: function () {
      this.showSnackbar("URL がクリップボードにコピーされました");
    },
    showSnackbar: function (text) {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = text;
    },
    //==============================
    // GoogleMap 関係
    //==============================
    applyGoogleMap: function () {
      const about = this.about;
      // 住所を Google Map に反映 (gps を入力させるのは現実的ではない)
      // - 初期表示時
      // - エンターキー押下時
      // - フォーカスアウト時
      if (!about) {
        return;
      }
      const address = about.address;
      const src = this.createGoogleMapSrc(address);
      this.googleMapSrc = src;
    },
    createGoogleMapSrc: function (address) {
      const URL_BASE = "https://maps.google.co.jp/maps?output=embed&q=";
      console.log("address", address);
      if (address) {
        return `${URL_BASE}${address}`;
      }
      return URL_BASE;
    },
    //==============================
    // 画像アップロード関係
    //==============================
    async initStorageView(imgUrl, iconUrl) {
      const storagePath = await this.getStoragePath({});
      console.log("[initStorageView]", storagePath, imgUrl, iconUrl);
      this.imgDirPath = `${storagePath}/img`;
      this.iconDirPath = `${storagePath}/icon`;
      this.initSelectImgUrl = imgUrl;
      this.initSelectIconUrl = iconUrl;
    },
    // 以下のタイミングで呼び出される
    // - 初期表示完了後 (初期選択画像 設定後)
    // - 画像選択時
    onImgSelected(imgData) {
      console.log(`[onImgSelected]${JSON.stringify(imgData)}`);
      this.currentSelectedImg = imgData;
    },
    onIconSelected(imgData) {
      console.log(`[onIconSelected]${JSON.stringify(imgData)}`);
      this.currentSelectedIcon = imgData;
    },
    // 以下のタイミングで呼び出される
    // - 画像削除時
    onImgDeleted(imgData) {
      console.log(`[onImgDeleted]${JSON.stringify(imgData)}`);
      this.currentSelectedImg = null;
    },
    onIconDeleted(imgData) {
      console.log(`[onIconDeleted]${JSON.stringify(imgData)}`);
      this.currentSelectedIcon = null;
    },
  },
};
</script>
<style scoped>
.googlemap {
  height: 300px;
}
.googlemap iframe {
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss" scoped>
.v-btn.v-size--large {
  font-size: 1.4rem;
}
</style>
